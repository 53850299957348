import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, List, ListItem, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'
import LimestoneImg from '../assets/Raw Material Trading/Limestone-.jpg'
import BoricImg from '../assets/Raw Material Trading/BORIC ACIDE.jpg'
import GrainImg from '../assets/Grain Trading/02.jpg'
import FiberglassImg from '../assets/Raw Material Trading/Fiberglass 01.jpg.png'
import GypsumImg from '../assets/Raw Material Trading/Gypsum Powder-.jpg'
import UreaImg from '../assets/Raw Material Trading/UREA01.jpg'
import sodiumImg from '../assets/Raw Material Trading/sodium_bicarbonate.jpg'
import PageContainer from '../components/layout/PageContainer'

const GrainTrading = () => {
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", padding: "5px 20px" }}>
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            Grain trading
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "50px", mt: "50px" }}>
                        <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>
                            The grain trade refers to the local and international trade in cereals such as wheat, barley, maize, and rice, and other food grains. Grain is an important trade item because it is easily stored and transported with limited spoilage, unlike other agricultural products. Healthy grain supply and trade is important to many societies, providing a caloric base for most food systems as well as important role in animal feed for animal agriculture.<br></br>
                            Our trade covers a broad range of food products as diverse as grains to soya, coffee, cocoa and sugar and spices.
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px" }}>
                            <Box
                                component="img"
                                alt="About image"
                                src={GrainImg}
                                sx={{ width: { xs: "100%", sm: "100%" }, borderRadius: "10px", mt: "20px" }}
                            />
                        </Box>
                    </Box>
                </Box>
            </PageContainer>
        </Layout>
    )
}

export default GrainTrading