import React from 'react'
import Header from '../components/header/Header'
import { Box, Button, Container, Typography } from '@mui/material'
import Layout from '../components/layout/Layout'
import { Link } from 'react-router-dom'
import HydraulicImg from '../assets/Our Business.  Heavy machinery spare parts trading.png'
import materialsImg from '../assets/Raw Material Trading/raw materials trading.png'
import TextileImg from '../assets/Textile Trading/Textile Trading.jpg'
import GrainImg from '../assets/Grain Trading/graintrading.png'
import PageContainer from '../components/layout/PageContainer'

const OurBusiness = () => {
    const services = [
        { title: "Heavy Equipment & Machinery Spare Parts Trading", caption: "Today, we are recognized as one of the largest heavy equipment and machinery spare parts business(Spare parts for all kinds of pumps, Hydraulic, bearing, drive components, engine Components,etc ) in the world and have extensive experience in all our business. We offer complete sales of heavy equipment and machinery spare parts worldwide.", imageSrc: HydraulicImg, route: "/business/heavy-machinery" },
        { title: "Textile Trading", caption: "The textile industry includes The classification.", imageSrc: TextileImg, route: "/business/textile-trading" },
        {
            title: "Raw Material Trading", caption: `Raw materials are materials or substances used in the primary production or manufacturing of goods. As the leading supplier of raw material  we work together with customers of the market world-wide.`, imageSrc: materialsImg, route: "/business/raw-materials"
        },
        {
            title: "Grain trading", caption: `The grain trade refers to the local and international trade in cereals such as wheat, barley, maize, and rice, and other food grains.`, imageSrc: GrainImg, route: "/business/grain-trading"
        },
    ]
    return (
        <Layout>
            <PageContainer>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "30px" }}>
                    <Box>
                        <Typography variant='h1' sx={{ fontSize: "36px", fontWeight: "bold" }}>
                            Our Business
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "50px", mt: "50px" }}>
                        {services.map((item) => {
                            return (
                                <Box sx={{
                                    display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: "50px", border: "1px solid #ec0000", padding: "20px 30px", transition: "0.2s", textDecoration: 'none',
                                    '&:hover': {
                                        boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, .2);",
                                    },
                                }}
                                    component={Link} to={item.route}
                                >
                                    <Box sx={{ width: { xs: "100%", sm: "20%" }, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Box
                                            component="img"
                                            alt="About image"
                                            src={item.imageSrc}
                                            sx={{ width: "100%", borderRadius: "5px" }}
                                        />
                                    </Box>
                                    <Box sx={{ width: { xs: "100%", sm: "80%" }, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "20px" }}>
                                        <Typography variant='h2' sx={{ fontSize: "24px", fontWeight: "600", color: "rgb(50 50 50)" }}>{item.title}</Typography>
                                        <Typography variant='caption' sx={{ fontSize: "18px", color: "rgb(90 90 90)" }}>{item.caption}</Typography>
                                    </Box>
                                </Box>
                            )
                        })}

                    </Box>
                </Box>
            </PageContainer>
        </Layout >
    )
}

export default OurBusiness